import { PROMO } from '~/constants/staticPages';

export default defineNuxtRouteMiddleware((to) => {
  const pageType = to.params.pageType as string;

  if (!Object.values(PROMO).includes(pageType)) {
    throw createError({
      statusCode: 404,
      message: 'Page not found',
      fatal: true,
    });
  }
});
