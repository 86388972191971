import { PLAYER_STATUS } from '~/constants/general';

export const GAMES_WAGERING_ONE_PERCENT : string[] = [
  'Castle Builder',
  'Forsaken Kingdom',
  'Jekyll and Hyde',
  'Scrooge',
  'Tomb Raider 2',
  'Big Bang™',
  'Blood Suckers™',
  'Robin Hood Shifting Riches',
  'The Wish Master™',
  '1429 Uncharted Seas',
  'Santa\'s Wonderland',
  'Spaceman',
  'Mines.',
];

export const TABLES_ITEMS = {
  paymentsPolicy: {
    headers: [
      {
        dictionaryKey: 'terms_and_conditions_payment_policy_table_items_headers.0',
        fieldName: 'name',
      },
      {
        dictionaryKey: 'terms_and_conditions_payment_policy_table_items_headers.1',
        fieldName: 'limitRUB',
      },
      {
        dictionaryKey: 'terms_and_conditions_payment_policy_table_items_headers.2',
        fieldName: 'limitUSD',
      },
      {
        dictionaryKey: 'terms_and_conditions_payment_policy_table_items_headers.3',
        fieldName: 'limitEUR',
      },
      {
        dictionaryKey: 'terms_and_conditions_payment_policy_table_items_headers.4',
        fieldName: 'limitKZT',
      },
    ],
    userData: [
      {
        name: PLAYER_STATUS.new,
        limitRUB: '200000',
        limitUSD: '3000',
        limitEUR: '3000',
        limitKZT: '1350000',
      },
      {
        name: PLAYER_STATUS.player,
        limitRUB: '200000',
        limitUSD: '3000',
        limitEUR: '3000',
        limitKZT: '1350000',
      },
      {
        name: PLAYER_STATUS.silver,
        limitRUB: '200000',
        limitUSD: '3000',
        limitEUR: '3000',
        limitKZT: '1350000',
      },
      {
        name: PLAYER_STATUS.gold,
        limitRUB: '330000',
        limitUSD: '5000',
        limitEUR: '5000',
        limitKZT: '2250000',
      },
      {
        name: PLAYER_STATUS.vip,
        limitRUB: '500000',
        limitUSD: '7500',
        limitEUR: '7500',
        limitKZT: '3375000',
      },
    ],
  },
  userStatus: {
    headers: [
      {
        dictionaryKey: 'terms_and_conditions_bonus_policy_table_items_headers.0',
        fieldName: 'status',
      },
      {
        dictionaryKey: 'terms_and_conditions_bonus_policy_table_items_headers.1',
        fieldName: 'betAmount',
      },
    ],
    itemsList: [
      {
        status: PLAYER_STATUS.new,
        betAmount: '0 - 999.99 USD',
      },
      {
        status: PLAYER_STATUS.player,
        betAmount: '1 000 - 4 999.99 USD',
      },
      {
        status: PLAYER_STATUS.silver,
        betAmount: '5 000 - 14 999.99 USD',
      },
      {
        status: PLAYER_STATUS.gold,
        betAmount: '15 000 - 34 999.99 USD',
      },
      {
        status: PLAYER_STATUS.vip,
        betAmount: '> 35 000 USD',
      },
    ],
  },
  statusDetails: {
    headers: [
      {
        dictionaryKey: 'terms_and_conditions_bonus_policy_table_status_influence_header_text',
        fieldName: 'status',
      },
      {
        dictionaryKey: PLAYER_STATUS.new,
        fieldName: 'statusNew',
      },
      {
        dictionaryKey: PLAYER_STATUS.player,
        fieldName: 'statusPlayer',
      },
      {
        dictionaryKey: PLAYER_STATUS.silver,
        fieldName: 'statusSilver',
      },
      {
        dictionaryKey: PLAYER_STATUS.gold,
        fieldName: 'statusGold',
      },
      {
        dictionaryKey: PLAYER_STATUS.vip,
        fieldName: 'statusVIP',
      },
    ],
    itemsList: [
      {
        status: 'terms_and_conditions_bonus_policy_table_status_influence_items.0',
        statusNew: '45',
        statusPlayer: '40',
        statusSilver: '35',
        statusGold: '35',
        statusVIP: '30',
      },
      {
        status: 'terms_and_conditions_bonus_policy_table_status_influence_items.1',
        statusNew: '5%',
        statusPlayer: '5%',
        statusSilver: '7%',
        statusGold: '7%',
        statusVIP: '10%',
      },
      {
        status: 'terms_and_conditions_bonus_policy_table_status_influence_items.2',
        statusNew: 'terms_and_conditions_payment_policy_table_no',
        statusPlayer: 'terms_and_conditions_payment_policy_table_no',
        statusSilver: 'terms_and_conditions_payment_policy_table_no',
        statusGold: 'terms_and_conditions_payment_policy_table_yes',
        statusVIP: 'terms_and_conditions_payment_policy_table_yes',
      },
    ],
  },
  percentageFundsReturn: {
    headers: [
      {
        dictionaryKey: 'general_player_status',
        fieldName: 'status',
      },
      {
        dictionaryKey: 'general_percent_return',
        fieldName: 'percentReturn',
      },
    ],
    itemsList: [
      {
        status: PLAYER_STATUS.new,
        percentReturn: '5 %',
      },
      {
        status: PLAYER_STATUS.player,
        percentReturn: '5 %',
      },
      {
        status: PLAYER_STATUS.silver,
        percentReturn: '7 %',
      },
      {
        status: PLAYER_STATUS.gold,
        percentReturn: '7 %',
      },
      {
        status: PLAYER_STATUS.vip,
        percentReturn: '10 %',
      },
    ],
  },
  depositsRanges: {
    headers: [
      {
        dictionaryKey: 'general_gift_amount',
        fieldName: 'giftAmount',
      },
      {
        dictionaryKey: 'general_deposit_amount',
        fieldName: 'depositAmount',
      },
    ],
    itemsList: [
      {
        giftAmount: '5 USD/EUR /300 RUB /2 250 KZT / 0,000205 BTC / 0,003 ETH / 0,055 LTC',
        depositAmount: 'promo_birthday_bonus.deposit_amount.0',
      },
      {
        giftAmount: '25 USD/EUR /1 500 RUB /11 250 KZT / 0,001025 BTC / 0,015 ETH / 0,275 LTC',
        depositAmount: 'promo_birthday_bonus.deposit_amount.1',
      },
      {
        giftAmount: '50 USD/EUR /3 000 RUB /22 500 KZT / 0,00205 BTC / 0,03ETH / 0,55 LTC',
        depositAmount: 'promo_birthday_bonus.deposit_amount.2',
      },
      {
        giftAmount: '100 USD/EUR /6 000 RUB /45 000 KZT / 0,0041 BTC / 0,06 ETH / 1,1 LTC',
        depositAmount: 'promo_birthday_bonus.deposit_amount.3',
      },
      {
        giftAmount: '200 USD/EUR /12 000 RUB /90 000 KZT / 0.0082 BTC / 0,12 ETH / 2,2 LTC',
        depositAmount: 'promo_birthday_bonus.deposit_amount.4',
      },
    ],
  },
};

export const PROMO = {
  startOffer: 'start-offer',
  weeklyAction: 'weekly-action',
  fridayBonus: 'friday-bonus',
  statuses: 'statuses',
  backDeposit: 'back-deposit',
  birthdayBonus: 'birthday-bonus',
};
